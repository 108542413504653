<template>
  <div class="foodindustry">
    <div class="desc box_padding">
      <h3>行业难题</h3>
      <p>国家重视食品质量安全溯源管理体系，要求食品可溯源，风险可管控。</p>
      <p>食品市场很多假冒伪劣商品严重危害消费者生命财产安全与企业品牌。</p>
      <div class="desc_img"></div>
      <p>当产品出现质量问题，难以有效批次召回。</p>
      <p>
        产品包装形式众多，产线速度快，发货量大，工厂、仓库个性化，方案难度高。
      </p>
    </div>
    <div class="desc_2 box_padding">
      <h3>解决方案</h3>
      <h3></h3>
      <div class="desc_img"></div>
      <p>结合标签标识与一体化赋码形式确保最小包装单位可溯源。</p>
      <p>搭建产品追溯体系，记录产品流通环节所有信息，实现产品可溯源召回。</p>
      <p>
        面对伪劣商品能为企业快速提供证据证明产品真伪，避免质监局查处与减少负面影响品牌声誉。
      </p>
      <p>
        结合多种营销互动提升用户体验，提升品牌竞争力，帮助企业收集终端大数据。
      </p>
      <img src="~@/assets/img/product/foodsafety_info.png" />
      <p>
        民以食为天，食以安为先，通过为每件产品量身定制，赋予其专属信息二维码并且利用“物联网云技术”，为企业提供从物流供应链溯源、生产投入品溯源
        产品生产流程管控、销售渠道追溯、数据统计、分析、产品流通快速召回等产业链全链条、全细节追溯，企业食品安全生产保驾护航！
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FoodIndustry",
};
</script>

<style lang="less" scoped>
.foodindustry {
  .box_padding {
    padding: 50px 30px;
  }

  h3 {
    font-size: 50px;
    font-weight: 700;
  }
  p {
    font-size: 35px;
    color: #333;
    margin: 20px 0;
    text-align: justify;
  }
  span {
    font-size: 35px;
    font-weight: 500;
  }
  img {
    width: 100%;
    border-radius: 10px;
    height: 400px;
    border: 2px solid #f0f0f0;
  }
  .desc_img {
    width: 100%;
    height: 400px;
    border: 2px solid #f0f0f0;
    background: url("~@/assets/img/product/foodindustry_1.jpg") no-repeat
      center/cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  .desc_2 {
    background-color: #f7f7f7;
    .desc_img {
      height: 250px;
      background: url("~@/assets/img/banner/foodsafety.jpg") no-repeat
        center/cover;
    }
  }
}
</style>